<script>
import LegalEntitiesForm from './LegalEntitiesForm'

export default {
  extends: LegalEntitiesForm,

  created() {
    this.getLegalEntity(this.$route.params.id).then(response => {
      this.form = response
    })
  },

  methods: {
    onSubmit() {
      this.updateLegalEntity(this.form.id, this.form)
        .then(() => {
          this.successSubmit({ message: 'Юр. лицо успешно изменено', routeName: 'legal-entities' })
        })
        .finally(() => { this.afterSubmit() })
    }
  }
}
</script>
